import {AppAction} from '../../app/AppAction';
import {Locale} from '../../model/Locale';
import {LocaleDirection, matchLocaleAnyDirection} from '../../model/LocaleDirection';
import {Translation} from '../../model/Translation';
import {EditTranslationEntry, duplicateReducer, toggleDuplicate} from '../editTranslation/EditTranslationEntry';

export type AddTranslationEntry = EditTranslationEntry;

export type AddTranslationsState = {
    from: Locale;
    to: Locale;
    translations: AddTranslationEntry[];
};

export const DEFAULT_ADD_TRANSLATIONS_STATE: AddTranslationsState = {
    from: 'de',
    to: 'en',
    translations: [{
        from: '',
        to: '',
        fromLocale: 'de',
        toLocale: 'en',
        duplicate: 'none',
    }],
};

const appendOptionalRow = (translations: AddTranslationEntry[], fromLocale: Locale, toLocale: Locale): AddTranslationEntry[] => {
    if (translations[translations.length - 1].to) {
        return [...translations, {from: '', to: '', fromLocale, toLocale, duplicate: 'none'}];
    }
    return translations;
};

const duplicateListReducer = (translations: AddTranslationEntry[], allTranslations: Translation[], from: Locale) => {
    return translations.map(translation => duplicateReducer(translation, allTranslations, from));
};

const newEntriesBatchReducer = (list: [string, string][], translations: AddTranslationEntry[], fromLocale: Locale, toLocale: Locale) => {
    list = list.concat();
    translations = translations.map(entry => {
        if (entry.from === '' && entry.to === '' && list.length > 0) {
            const [from, to] = list.shift()!;
            return {...entry, from, to};
        }
        return entry;
    });
    while (list.length > 0) {
        const [from, to] = list.shift()!;
        translations.push({from, to, fromLocale, toLocale, duplicate: 'none'});
    }
    return translations;
};

const initializeState = (state: AddTranslationsState) => {
    return {
        ...DEFAULT_ADD_TRANSLATIONS_STATE,
        from: state.from,
        to: state.to,
        translations: DEFAULT_ADD_TRANSLATIONS_STATE.translations.map(translation => ({...translation, fromLocale: state.from, toLocale: state.to})),
    };
};

export const addTranslationsReducer = (state: AddTranslationsState, action: AppAction, allTranslations: Translation[], currentLanguages?: LocaleDirection): AddTranslationsState => {
    switch (action.type) {
        case 'SHOW_ADD_TRANSLATIONS':
            if (currentLanguages && !matchLocaleAnyDirection(currentLanguages.fromLocale, currentLanguages.toLocale, state.from, state.to)) {
                state = {...state, from: currentLanguages.fromLocale, to: currentLanguages.toLocale};
            }
            return initializeState(state);
        case 'ADD_TRANSLATIONS':
            return initializeState(state);
        case 'UPDATE_TRANSLATION_TEXT': {
            let translations = state.translations.map((translation, index) => {
                if (index === action.row) {
                    return action.translation;
                }
                return translation;
            });
            translations = duplicateListReducer(translations, allTranslations, state.from);
            return {
                ...state,
                translations: appendOptionalRow(translations, state.from, state.to)
            };
        }
        case 'SWITCH_ADD_TRANSLATION_DIRECTION':
            return {
                ...state,
                from: state.to,
                to: state.from,
                translations: state.translations.map(({from, fromLocale, to, toLocale, duplicate}) => ({
                    from: to,
                    fromLocale: toLocale,
                    to: from,
                    toLocale: fromLocale,
                    duplicate: toggleDuplicate(duplicate),
                })),
            };
        case 'ADD_MULTIPLE_TRANSLATION_TEXTS': {
            let translations = newEntriesBatchReducer(action.list, state.translations, state.from, state.to);
            translations = duplicateListReducer(translations, allTranslations, state.from);
            translations = appendOptionalRow(translations, state.from, state.to);
            return {...state, translations};
        }
    }
    return state;
};