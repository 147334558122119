import {FC} from 'react';
import {Wordings} from '../Wordings';
import {AppState} from '../app/AppState';
import {useMessageBox} from '../useMessageBox';
import {Button} from './Button';
import {Dialog} from './Dialog';
import styles from './ExportDialog.module.css';
import {TextField} from './TextField';

type Props = {
    className?: string;
    state: AppState;
    onClose: () => void;
};

const download = (content: any, filename: string, contentType: string) => {
    const a = document.createElement('a');
    const file = new Blob([content], {type: contentType});

    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();

    URL.revokeObjectURL(a.href);
    a.remove();
};

export const ExportDialog: FC<Props> = ({className, state, onClose}) => {
    const showMessage = useMessageBox();
    const text = JSON.stringify({
        translations: state.translations,
        boxes: state.trainingViewState.boxes,
    }, undefined, '  ');

    const copy = () => navigator.clipboard.writeText(text)
        .then(() => {
            onClose();
            showMessage({title: Wordings.hint, message: Wordings.export.copied});
        });

    const save = () => {
        const timestamp = (new Date()).toISOString().match(/\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d/)![0].replace(/T/, '_').replace(/[-:]/g, '');
        download(text, `vokabi_export_${timestamp}.json`, 'application/json');
        onClose();
    };

    return <Dialog dialogClassName={styles.dialog}>
        <div className={styles.content}>
            <div className={styles.title}>{Wordings.export.title}</div>
            <TextField text={text} className={styles.textField} />
            <div className={styles.buttons}>
                <Button onClick={save}>{Wordings.export.save}</Button>
                <Button onClick={copy}>{Wordings.export.copy}</Button>
                <Button onClick={onClose}>{Wordings.close}</Button>
            </div>
        </div>
    </Dialog>;
};