import {cls} from '@goser/common';
import {ClipboardEventHandler} from 'react';
import {FC, ChangeEventHandler} from 'react';
import styles from './Input.module.css';

type Props = {
    className?: string
    value?: string
    onChange?: ChangeEventHandler<HTMLInputElement>
    placeholder?: string
    onPaste?: ClipboardEventHandler<HTMLInputElement>
}

export const Input: FC<Props> = ({className, value, onChange, placeholder, onPaste}) => {
    return <input type='text' autoCorrect='off' autoCapitalize='off' autoComplete='off'
        className={cls(className, styles.input)} value={value}
        onPaste={onPaste} onChange={onChange} placeholder={placeholder} />;
}