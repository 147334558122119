import {cls} from '@goser/common';
import {FC} from 'react';
import {Card} from '../../model/Card';
import {Locale} from '../../model/Locale';
import {Wordings} from '../../Wordings';
import styles from './CardPanel.module.css';

type Props = {
    className?: string;
    card: Card;
    fromLocale: Locale;
    toLocale: Locale;
    reveal: boolean;
};

export const CardPanel: FC<Props> = ({className, card, fromLocale, toLocale, reveal}) => {
    const {text, translations, level} = card;
    return <div className={cls(className, styles.main)}>
        <div className={styles.fromLocale}>{Wordings.language[fromLocale]}</div>
        <div className={styles.text}>{text}</div>
        <div className={styles.toLocale}>{Wordings.language[toLocale]}</div>
        <div className={styles.translations}>
            {translations.map((translation, index) => {
                return <div key={translation + '_' + index} className={styles.translation}>{reveal ? translation : '?'}</div>;
            })}
        </div>
        <div className={styles.level}>{Wordings.training.level(level + 1)}</div>
    </div>;
};