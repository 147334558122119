import {FC} from 'react';
import {Button} from '../../component/Button';
import {View} from '../../component/View';
import {Locale} from '../../model/Locale';
import {Wordings} from '../../Wordings';
import {AppActionDispatch} from '../../app/AppAction';
import {AppState} from '../../app/AppState';

type Props = {
    className?: string;
    dispatch: AppActionDispatch;
    state: AppState;
};

export const LocaleChooserView: FC<Props> = ({className, dispatch, state}) => {
    const {fromLocale, toLocale} = state.currentLanguages!;
    const directions: [Locale, Locale][] = [
        [fromLocale, toLocale],
        [toLocale, fromLocale],
    ];
    return <View title={Wordings.localeChooser.title} dispatch={dispatch}>
        {directions.map(([from, to]) => {
            return <Button key={from + '_' + to} onClick={() => {
                dispatch({type: 'CHOOSE_DIRECTION', from, to});
                dispatch({type: 'SHOW_TRAINING'});
            }}>
                {Wordings.languagePair(from, to)}
            </Button>;
        })}
    </View>;
};