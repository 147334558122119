import {cls} from '@goser/common';
import {FC} from 'react';
import {createPortal} from 'react-dom';
import styles from './LoadIndicator.module.css';

type Props = {
    className?: string
}

export const LoadIndicator: FC<Props> = ({className}) => {
    return createPortal(
        <div className={cls(className, styles.main)}>
            <div className={styles.first}></div>
            <div className={styles.second}></div>
            <div className={styles.third}></div>
        </div>,
        document.querySelector('#loader-root')!
    );
}