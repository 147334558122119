import {cls} from '@goser/common';
import {FC} from 'react';
import {Wordings} from '../../Wordings';
import {AppActionDispatch} from '../../app/AppAction';
import {Input} from '../../component/Input';
import {SimplePaging} from '../../component/SimplePaging';
import {View} from '../../component/View';
import styles from './ListTranslationsView.module.css';
import {ListTranslationsViewElement} from './ListTranslationsViewElement';
import {ListTranslationsViewState} from './ListTranslationsViewState';
import {RemoveTranslationDialog} from './RemoveTranslationDialog';

type Props = {
    className?: string;
    dispatch: AppActionDispatch;
    state: ListTranslationsViewState;
};

export const ListTranslationsView: FC<Props> = ({className, dispatch, state}) => {
    const {fromLocale, toLocale} = state.currentLanguages;

    return <View className={cls(className, styles.main)} title={Wordings.list.title} dispatch={dispatch}>
        <Input onChange={event => dispatch({type: 'UPDATE_FILTER', filter: event.target.value})}
            value={state.filter} placeholder={Wordings.list.filterPlaceholder} />

        <SimplePaging dispatch={dispatch} page={state.page} maxPage={state.maxPage} />
        <div className={styles.list}>
            <div className={styles.caption}>
                <div>{Wordings.language[fromLocale]}</div>
                <div>{Wordings.language[toLocale]}</div>
            </div>
            {state.translations.map((translation, index) => {
                return <ListTranslationsViewElement key={index} translation={translation} fromLocale={fromLocale} dispatch={dispatch} />;
            })}
        </div>
        <SimplePaging dispatch={dispatch} page={state.page} maxPage={state.maxPage} />
        {state.removeTranslation && <RemoveTranslationDialog dispatch={dispatch} translation={state.removeTranslation} />}
    </View>;
};