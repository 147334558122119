import {Locale} from '../../model/Locale';
import {getTranslationSorter, Translation} from '../../model/Translation';
import {AppAction} from '../../app/AppAction';
import {DEFAULT_CURRENT_LANGUAGES, LocaleDirection, localeDirectionAnyMatcher} from '../../model/LocaleDirection';

export type ListTranslationsViewState = {
    translations: Translation[];
    pageSize: number;
    page: number;
    maxPage: number;
    fromLocale: Locale;
    toLocale: Locale;
    filter: string;
    removeTranslation: Translation | null;
    currentLanguages: LocaleDirection;
};

export const DEFAULT_LIST_TRANSLATIONS_VIEW_STATE: ListTranslationsViewState = {
    translations: [],
    pageSize: 20,
    page: 0,
    maxPage: 0,
    fromLocale: 'de',
    toLocale: 'en',
    filter: '',
    removeTranslation: null,
    currentLanguages: DEFAULT_CURRENT_LANGUAGES
};

const reducer = (state: ListTranslationsViewState, translations: Translation[], pageTransform: (page: number, maxPage: number) => number, currentLanguages: LocaleDirection) => {
    const {fromLocale, pageSize, filter} = state;
    translations = translations.filter(localeDirectionAnyMatcher(currentLanguages.fromLocale, currentLanguages.toLocale));
    if (filter) {
        translations = translations.filter(translations =>
            translations.from.toLowerCase().includes(filter.toLowerCase()) ||
            translations.to.toLowerCase().includes(filter.toLowerCase())
        );
    }
    const count = translations.length;
    const maxPage = Math.ceil((count / pageSize) - 1);
    const page = Math.min(maxPage, Math.max(0, pageTransform(state.page, maxPage)));
    const startIndex = page * pageSize;
    const endIndex = Math.min(startIndex + pageSize, count);
    translations = translations
        .sort(getTranslationSorter(fromLocale))
        .slice(startIndex, endIndex);
    return {
        ...state,
        page,
        maxPage,
        translations,
        currentLanguages,
    };
};

export const listTranslationsViewReducer = (state: ListTranslationsViewState, action: AppAction, translations: Translation[], currentLanguages?: LocaleDirection): ListTranslationsViewState => {
    if (currentLanguages) {
        switch (action.type) {
            case 'SHOW_LIST_TRANSLATIONS':
                return reducer(DEFAULT_LIST_TRANSLATIONS_VIEW_STATE, translations, (page, maxPage) => 0, currentLanguages);
            case 'SHOW_FIRST_PAGE':
                return reducer(state, translations, (page, maxPage) => 0, currentLanguages);
            case 'SHOW_NEXT_PAGE':
                return reducer(state, translations, (page, maxPage) => page + 1, currentLanguages);
            case 'SHOW_PREVIOUS_PAGE':
                return reducer(state, translations, (page, maxPage) => page - 1, currentLanguages);
            case 'SHOW_LAST_PAGE':
                return reducer(state, translations, (page, maxPage) => maxPage, currentLanguages);
            case 'UPDATE_FILTER':
                return reducer({...state, filter: action.filter}, translations, page => page, currentLanguages);
            case 'SHOW_REMOVE_TRANSLATION_DIALOG':
                return reducer({...state, removeTranslation: action.translation}, translations, page => page, currentLanguages);
            case 'HIDE_REMOVE_TRANSLATION_DIALOG':
            case 'REMOVE_TRANSLATION':
                // TODO check removal of "(=cannot)"
                return reducer({...state, removeTranslation: null}, translations, page => page, currentLanguages);
        }
    }
    return state;
};