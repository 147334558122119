import {cls} from '@goser/common';
import {FC} from 'react';
import {Boxes} from '../../component/Boxes';
import {Button} from '../../component/Button';
import {View, ViewNavbar} from '../../component/View';
import {Wordings} from '../../Wordings';
import {AppActionDispatch} from '../../app/AppAction';
import {CardPanel} from './CardPanel';
import styles from './TrainingView.module.css';
import {TrainingViewState} from './TrainingViewState';

type Props = {
    className?: string;
    dispatch: AppActionDispatch;
    state: TrainingViewState;
};

export const TrainingView: FC<Props> = ({className, dispatch, state}) => {
    const {fromLocale, toLocale, reveal, currentBox, currentCard, processed} = state;
    const {solved, unsolved} = processed;
    return <View className={cls(className, styles.main)} title={Wordings.training.title} dispatch={dispatch}>
        <ViewNavbar>
            {currentCard && <Button onClick={() => dispatch({type: 'SHUFFLE_CARDS'})}>{Wordings.training.shuffle}</Button>}
            <div className={styles.viewCount}>Gelöst: {solved}, Ungelöst: {unsolved}, Gesamt: {solved + unsolved}</div>
        </ViewNavbar>
        {
            currentCard
                ? <>
                    {currentBox && <Boxes cards={currentBox.cards} level={currentBox.level} />}
                    <CardPanel card={currentCard} toLocale={toLocale} fromLocale={fromLocale} reveal={reveal} />
                    {reveal
                        ? <div className={styles.knownBar}>
                            <div>{Wordings.training.known}</div>
                            <Button onClick={() => dispatch({type: 'CARD_SOLVED', card: currentCard!})}>{Wordings.training.yes}</Button>
                            <Button onClick={() => dispatch({type: 'CARD_FAILED', card: currentCard!})}>{Wordings.training.no}</Button>
                        </div>
                        : <Button onClick={() => dispatch({type: 'REVEAL_TRANSLATIONS'})} className={styles.solve}>{Wordings.training.reveal}</Button>
                    }
                </>
                : (
                    currentBox?.solved
                        ? <>
                            <div>{Wordings.training.noFurtherCardsInBox}</div>
                            <Button onClick={() => dispatch({type: 'DOWN_SOLVED_CARDS_ONE_LEVEL'})}>{Wordings.training.oneLevelDown}</Button>
                            <Button onClick={() => dispatch({type: 'DOWN_SOLVED_CARDS_TO_LEVEL_ONE'})}>{Wordings.training.toLevelOne}</Button>
                        </>
                        : <>
                            <div>{Wordings.training.noFurtherCardsThisRound}</div>
                        </>
                )
        }
    </View>;
};