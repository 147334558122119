import {cls} from '@goser/common';
import {FC} from 'react';
import styles from './TextField.module.css';

type Props = {
    className?: string
    text: string
}

export const TextField: FC<Props> = ({className, text}) => {
    return <textarea className={cls(className, styles.main)} value={text} readOnly={true}></textarea>;
}