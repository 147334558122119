import {cls} from '@goser/common';
import {FC} from 'react';
import {Wordings} from '../../Wordings';
import {AppActionDispatch} from '../../app/AppAction';
import {Button} from '../../component/Button';
import {Input} from '../../component/Input';
import {View} from '../../component/View';
import {EditTranslationState} from './EditTranslationState';
import styles from './EditTranslationView.module.css';

type Props = {
    className?: string;
    dispatch: AppActionDispatch;
    state: EditTranslationState;
};

export const EditTranslationView: FC<Props> = ({className, dispatch, state}) => {
    const {from, to, fromLocale, toLocale, duplicate, translation} = state;

    let fromClassName = (duplicate === 'from' || duplicate === 'each') && styles.duplicate;
    let toClassName = (duplicate === 'to' || duplicate === 'each') && styles.duplicate;
    if (duplicate === 'both') {
        fromClassName = styles.blockedDuplicate;
        toClassName = styles.blockedDuplicate;
    }

    return <View className={className} title={Wordings.edit.title} dispatch={dispatch}>
        <div>{Wordings.language[fromLocale]}</div>
        <Input value={from} className={cls(fromClassName)}
            onChange={event => dispatch({type: 'UPDATE_EDIT_TRANSLATION_TEXT', from: event.target.value, to})} />
        <div>{Wordings.language[toLocale]}</div>
        <Input value={to} className={cls(toClassName)}
            onChange={event => dispatch({type: 'UPDATE_EDIT_TRANSLATION_TEXT', from, to: event.target.value})} />
        <div className={styles.buttons}>
            <Button onClick={() => dispatch({type: 'SHOW_HOME'})}>{Wordings.edit.cancel}</Button>
            <Button enabled={duplicate !== 'both'} onClick={() => dispatch({type: 'UPDATE_TRANSLATION', translation: translation!, from, to})}>
                {Wordings.edit.submit}
            </Button>
        </div>
    </View>;
};

// TODO card reset
// TODO save