import {cls} from '@goser/common';
import {forwardRef} from 'react';
import {Button, ButtonProps} from './Button';
import styles from './IconButton.module.css';

type Props = {
    char: string
} & Omit<ButtonProps, 'children'>;

export const IconButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const {className, char} = props;
    props = {...props};
    delete props.className;
    return <Button {...props} className={cls(className, styles.button)} ref={ref}>{char}</Button>;
})