import {FC} from 'react';
import {Button} from '../../component/Button';
import {View} from '../../component/View';
import {Wordings} from '../../Wordings';
import {AppActionDispatch} from '../../app/AppAction';
import {HomeViewState} from './HomeViewState';

type Props = {
    className?: string;
    dispatch: AppActionDispatch;
    state: HomeViewState;
};

export const HomeView: FC<Props> = ({className, state, dispatch}) => {
    const onLogoutClick = () => {
        const url = '/api/load.php';
        console.log('url', url);
        const request = new XMLHttpRequest();

        request.open('GET', url, false, 'log', 'out' + Math.random());
        request.setRequestHeader("Authorization", "Basic logout");
        request.addEventListener('error', () => {
            console.log("got error");
        });
        request.addEventListener('loadend', () => {
            console.log('loadend');
            window.location.reload();
        });
        request.send();
    };
    const {fromLocale, toLocale} = state.currentLanguages;
    return <View className={className} title={Wordings.home.title} dispatch={dispatch}>
        <div>{Wordings.languagePair(fromLocale, toLocale)}</div>
        <div>{Wordings.home.translationCount(state.translationCount)}</div>
        <Button onClick={() => dispatch({type: 'SHOW_LOCALE_CHOOSER'})}>{Wordings.home.training}</Button>
        <Button onClick={() => dispatch({type: 'SHOW_LIST_TRANSLATIONS'})}>{Wordings.home.listTranslations}</Button>
        <Button onClick={() => dispatch({type: 'SHOW_ADD_TRANSLATIONS'})}>{Wordings.home.addTranslations}</Button>
        <Button onClick={() => dispatch({type: 'SHOW_LANGUAGE_CHOOSER'})}>{Wordings.home.switchLanguages}</Button>
        <Button onClick={onLogoutClick}>{Wordings.home.logout}</Button>
    </View>;
};