import {Locale} from './model/Locale';

export const Wordings = {
    toHome: 'Zum Hauptmenü',
    back: 'Zurück',
    yes: 'Ja',
    no: 'Nein',
    ok: 'Ok',
    cancel: 'Abbrechen',
    close: 'Schließen',
    error: 'Fehler',
    hint: 'Hinweis',
    language: {
        de: 'Deutsch',
        en: 'Englisch',
        la: 'Latein',
    },
    languagePair: (from: Locale, to: Locale) => `${Wordings.language[from]} - ${Wordings.language[to]}`,
    home: {
        title: 'Vokabeltrainer',
        translationCount: (count: number) => `Es sind aktuell ${count} Übersetzungen eingepflegt`,
        addTranslations: 'Übersetzungen hinzufügen',
        switchLanguages: 'Sprache wechseln',
        listTranslations: 'Alle anzeigen',
        training: 'Übung',
        backup: 'Backup',
        logout: 'Ausloggen',
    },
    list: {
        title: 'Alle Übersetzungen',
        filterPlaceholder: 'Filter...',
        deleteTranslation: 'Übersetzung entfernen',
        editTranslation: 'Übersetzung bearbeiten',
    },
    add: {
        title: 'Übersetzungen hinzufügen',
        submit: 'Hinzufügen',
        switch: 'Richtung wechseln',
    },
    localeChooser: {
        title: 'Richtung wählen',
    },
    training: {
        title: 'Übung',
        level: (level: number) => `Level ${level}`,
        reveal: 'Aufdecken',
        known: 'Hast Du es gewusst?',
        yes: 'Ja',
        no: 'Nein',
        noFurtherCardsThisRound: 'Keine weiteren Karten für diese Runde.',
        noFurtherCardsInBox: 'Keine weiteren Karten in dieser Box.',
        oneLevelDown: 'Alle Karten eine Stufe herabsetzen',
        toLevelOne: 'Alle Karten auf die erste Stufe herabsetzen',
        shuffle: 'Mischen',
    },
    removeTranslation: {
        title: 'Übersetzung entfernen',
        question: (from: string, to: string) => `Willst Du '${from}' -> '${to}' wirklich löschen?`,
    },
    loadFailed: 'Laden des Backups fehlgeschlagen',
    saveFailed: 'Speichern des Backups fehlgeschlagen',
    export: {
        title: 'Daten exportieren',
        copy: 'Kopieren',
        copied: 'Die Daten wurden in die Zwischenablage kopiert.',
        save: 'Download',
    },
    duplicates: {
        title: 'Duplikate'
    },
    edit: {
        title: 'Bearbeiten',
        cancel: 'Abbrechen',
        submit: 'Speichern',
    },
    chooseLanguage: {
        title: 'Sprache wählen'
    }
};