import {cls} from '@goser/common';
import {forwardRef, MouseEventHandler, PropsWithChildren} from 'react';
import styles from './Button.module.css';

export type ButtonProps = PropsWithChildren & {
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
    disabled?: boolean;
    enabled?: boolean;
    tooltip?: string;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({className, children, onClick, disabled, enabled, tooltip}, ref) => {
    if (enabled === true) {
        disabled = false;
    } else if (enabled === false) {
        disabled = true;
    }
    return <button className={cls(className, styles.main)} onClick={onClick} disabled={disabled} title={tooltip} ref={ref}>{children}</button>;
});