import {cls, isDescendant} from '@goser/common';
import {FC, useEffect, useRef, useState} from 'react';
import {IconButton} from '../../component/IconButton';
import {Locale} from '../../model/Locale';
import {Translation} from '../../model/Translation';
import {Wordings} from '../../Wordings';
import {AppActionDispatch} from '../../app/AppAction';
import styles from './ListTranslationsViewElement.module.css';

type Props = {
    className?: string
    translation: Translation
    fromLocale: Locale
    dispatch: AppActionDispatch
}

// TODO edit translation

export const ListTranslationsViewElement: FC<Props> = ({className, translation, fromLocale, dispatch}) => {
    const [actionsActive, setActionsActive] = useState(false);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const invert = translation.fromLocale !== fromLocale
    const from = invert ? translation.to : translation.from;
    const to = invert ? translation.from : translation.to;

    useEffect(() => {
        if (!actionsActive) {
            return;
        }
        const listener = (event: MouseEvent) => {
            const target = event.target as Element;
            if (isDescendant(buttonRef!.current as Element, target) || isDescendant(menuRef.current as Element, target)) {
                return;
            }
            setActionsActive(false);
        };
        window.addEventListener('mousedown', listener);
        return () => {
            window.removeEventListener('mousedown', listener);
        }
    }, [actionsActive]);

    const onRemoveButtonClick = () => {
        setActionsActive(false);
        dispatch({type: 'SHOW_REMOVE_TRANSLATION_DIALOG', translation});
    }

    const onEditButtonClick = () => {
        setActionsActive(false);
        dispatch({type: 'EDIT_TRANSLATION', translation});
    };

    return <div className={cls(className, styles.main)} onClick={() => setActionsActive(true)}>
        <div className={styles.cell}>{from}</div>
        <div className={styles.cell}>{to}</div>
        {actionsActive && <div className={styles.actions}>
            <div className={styles.menu} ref={menuRef}>
                <IconButton onClick={onEditButtonClick} char='e' tooltip={Wordings.list.editTranslation} />
                <IconButton onClick={onRemoveButtonClick} char='⨯' tooltip={Wordings.list.deleteTranslation} />
            </div>
        </div>}
    </div>;
}