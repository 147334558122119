import {FC} from 'react';
import {MessageBox} from '../../component/MessageBox';
import {Translation} from '../../model/Translation';
import {Wordings} from '../../Wordings';
import {AppActionDispatch} from '../../app/AppAction';

type Props = {
    className?: string
    dispatch: AppActionDispatch
    translation: Translation
}

export const RemoveTranslationDialog: FC<Props> = ({className, dispatch, translation}) => {
    return <MessageBox className={className} title={Wordings.removeTranslation.title}
        message={Wordings.removeTranslation.question(translation.from, translation.to)}
        yesCallback={() => dispatch({type: 'REMOVE_TRANSLATION', translation})}
        noCallback={() => dispatch({type: 'HIDE_REMOVE_TRANSLATION_DIALOG'})}
    />
}