import {FC, PropsWithChildren, useEffect} from 'react';
import {createPortal} from 'react-dom';
import styles from './Modal.module.css';

type Props = PropsWithChildren & {
    open: boolean
}

let modalCount = 0;

export const Modal: FC<Props> = ({children, open}) => {
    useEffect(() => {
        modalCount++;
        document.body.dataset.hasModals = '' + (modalCount > 0);
        return () => {
            modalCount--;
            document.body.dataset.hasModals = '' + (modalCount > 0);
        };
    }, []);
    if (!open) {
        return null;
    }
    return createPortal(
        <div className={styles.container}>
            {children}
        </div>,
        document.querySelector('#modal-root')!
    );
}