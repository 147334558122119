import {cls} from '@goser/common';
import {FC, PropsWithChildren, useEffect, useRef, useState} from 'react';
import styles from './SettingsMenu.module.css';

type Props = PropsWithChildren & {
    className?: string;
};

export const SettingsMenu: FC<Props> = ({className, children}) => {
    const [isOpen, setOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const listener = (event: MouseEvent) => {
            let target: HTMLElement | null = event.target as HTMLElement;
            while (target != null) {
                if (target === buttonRef.current) {
                    return;
                }
                target = target.parentElement;
            }
            setOpen(false);
        };
        window.addEventListener('click', listener);
        return () => {
            window.removeEventListener('click', listener);
        };
    }, []);

    return <div className={cls(className, styles.main)}>
        <div className={styles.button} ref={buttonRef} onClick={() => setOpen(!isOpen)}>⋮</div>
        {isOpen && <div className={styles.menu} ref={menuRef}>
            {children}
        </div>}
    </div>;
};