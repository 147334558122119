import {AppAction} from '../../app/AppAction';
import {DEFAULT_CURRENT_LANGUAGES, LocaleDirection, localeDirectionAnyMatcher} from '../../model/LocaleDirection';
import {Translation} from '../../model/Translation';

export type HomeViewState = {
    translationCount: number;
    currentLanguages: LocaleDirection;
};

export const DEFAULT_HOME_VIEW_STATE: HomeViewState = {
    translationCount: 0,
    currentLanguages: DEFAULT_CURRENT_LANGUAGES
};

export const homeViewReducer = (state: HomeViewState, action: AppAction, translations: Translation[], currentLanguages?: LocaleDirection): HomeViewState => {
    if (currentLanguages) {
        const translationCount = translations.filter(localeDirectionAnyMatcher(currentLanguages.fromLocale, currentLanguages.toLocale)).length;
        return {...state, translationCount, currentLanguages: currentLanguages};
    }
    return state;
};