import {ClipboardEvent, FC} from 'react';
import {Wordings} from '../../Wordings';
import {AppActionDispatch} from '../../app/AppAction';
import {Button} from '../../component/Button';
import {View, ViewNavbar} from '../../component/View';
import {Locale} from '../../model/Locale';
import {Translation} from '../../model/Translation';
import {getAllDuplicatesFor} from '../editTranslation/EditTranslationEntry';
import {AddTranslationEntry, AddTranslationsState} from './AddTranslationsState';
import styles from './AddTranslationsView.module.css';
import {TranslationRow} from './TranslationRow';

type Props = {
    className?: string;
    state: AddTranslationsState;
    dispatch: AppActionDispatch;
    allTranslations: Translation[];
};


export const AddTranslationsView: FC<Props> = ({className, state, dispatch, allTranslations}) => {
    const {translations} = state;
    const hasTranslations = !!translations.find(translation => translation.from && translation.to);
    const onAddButtonClick = () => {
        dispatch({type: 'ADD_TRANSLATIONS', translations: translations.filter(translation => translation.from && translation.to)});
    };
    const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
        const list = event.clipboardData
            .getData('text')
            .trim()
            .split('\n')
            .map(s => s.trim().split('#'))
            .filter(entry => entry.length === 2 && entry[0].length > 0 && entry[1].length > 0) as [string, string][];
        if (list.length > 0) {
            event.preventDefault();
            dispatch({type: "ADD_MULTIPLE_TRANSLATION_TEXTS", list});
        }
    };
    const duplicatesProvider = (translation: AddTranslationEntry, from: Locale) => getAllDuplicatesFor(translation, allTranslations, from);
    return <View className={className} title={Wordings.add.title} dispatch={dispatch}>
        <ViewNavbar>
            <Button onClick={() => dispatch({type: 'SWITCH_ADD_TRANSLATION_DIRECTION'})}>{Wordings.add.switch}</Button>
        </ViewNavbar>
        <div className={styles.table}>
            <div>{Wordings.language[state.from]}</div>
            <div>{Wordings.language[state.to]}</div>
            {translations.map((translation, index) => {
                return <TranslationRow key={index} translation={translation} row={index}
                    onPaste={onPaste}
                    onChange={(translation, row) => dispatch({type: 'UPDATE_TRANSLATION_TEXT', translation, row})}
                    duplicatesProvider={duplicatesProvider} />;
            })}
        </div>
        <Button onClick={onAddButtonClick} disabled={!hasTranslations} className={styles.add}>{Wordings.add.submit}</Button>
    </View>;
};