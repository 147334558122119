import {cls, range} from '@goser/common';
import {FC} from 'react';
import {Card, MAX_CARD_LEVEL} from '../model/Card';
import styles from './Boxes.module.css';

type Props = {
    className?: string;
    cards: Card[];
    level: number;
};

export const Boxes: FC<Props> = ({className, cards, level}) => {
    const counts: number[] = range(MAX_CARD_LEVEL + 1).map(_ => 0);
    cards.forEach(({level}) => counts[level]++);
    return <div className={cls(className, styles.main)}>
        {counts.map((count, countIndex) => {
            return <div className={cls(styles.box, level === countIndex && styles.current)} key={'count_' + countIndex}>
                <div className={styles.cards}>
                    {range(count).map(cardIndex => {
                        return <div className={styles.card} key={'card_' + countIndex + '_' + cardIndex} />;
                    })}
                </div>
                <div className={styles.border} />
                <div className={styles.count}>{count}</div>
            </div>;
        })}
    </div>;
};