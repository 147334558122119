import {Locale} from './Locale';

export type Translation = {
    from: string;
    to: string;
    fromLocale: Locale;
    toLocale: Locale;
};


const toggleTranslation = (translation: Translation): Translation => {
    return {from: translation.to, to: translation.from, fromLocale: translation.toLocale, toLocale: translation.fromLocale};
};

export const alignTranslation = (translation: Translation, fromLocale: Locale) => {
    return translation.fromLocale === fromLocale ? translation : toggleTranslation(translation);
};

export const translationLocaleAlignmentMapper = (fromLocale: Locale) => {
    return (translation: Translation) => alignTranslation(translation, fromLocale);
};

const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

export const getTranslationSorter = (fromLocale: Locale) => (a: Translation, b: Translation) => {
    a = alignTranslation(a, fromLocale);
    b = alignTranslation(b, fromLocale);
    const fromCompared = collator.compare(a.from, b.from);
    if (fromCompared !== 0) {
        return fromCompared;
    }
    return collator.compare(a.to, b.to);
};

