import {Locale} from './Locale';

export type LocaleDirection = {fromLocale: Locale, toLocale: Locale;};

export const matchLocaleDirection = (fromA: Locale, toA: Locale, fromB: Locale, toB: Locale) =>
    fromA === fromB && toA === toB;

export const matchLocaleAnyDirection = (fromA: Locale, toA: Locale, fromB: Locale, toB: Locale) =>
    matchLocaleDirection(fromA, toA, fromB, toB) || matchLocaleDirection(fromA, toA, toB, fromB);

export const localeDirectionMatcher = (fromLocale: Locale, toLocale: Locale) =>
    (direction: LocaleDirection) => matchLocaleDirection(direction.fromLocale, direction.toLocale, fromLocale, toLocale);

export const localeDirectionAnyMatcher = (fromLocale: Locale, toLocale: Locale) =>
    (direction: LocaleDirection) => matchLocaleAnyDirection(direction.fromLocale, direction.toLocale, fromLocale, toLocale);

export const SUPPORTED_DIRECTIONS: LocaleDirection[] = [
    {fromLocale: 'de', toLocale: 'en'},
    {fromLocale: 'de', toLocale: 'la'},
];

export const DEFAULT_CURRENT_LANGUAGES = SUPPORTED_DIRECTIONS[0];