import {cls} from '@goser/common';
import {ClipboardEventHandler, FC} from 'react';
import {Input} from '../../component/Input';
import {AddTranslationEntry} from './AddTranslationsState';
import styles from './AddTranslationsView.module.css';
import {Locale} from '../../model/Locale';

type RowProps = {
    translation: AddTranslationEntry;
    row: number;
    onChange: (translation: AddTranslationEntry, row: number) => void;
    onPaste: ClipboardEventHandler<HTMLInputElement>;
    duplicatesProvider: (translation: AddTranslationEntry, from: Locale) => [string, string][];
};

const duplicateRenderer = ([from, to]: [string, string], index: number) => <div key={index}>{from} - {to}</div>;

export const TranslationRow: FC<RowProps> = ({translation, row, onChange, onPaste, duplicatesProvider}) => {
    const {duplicate} = translation;
    let fromClassName = '';
    if (duplicate === 'from' || duplicate === 'each') {
        fromClassName = styles.duplicate;
    }
    let toClassName = (duplicate === 'to' || duplicate === 'each') && styles.duplicate;
    if (duplicate === 'both') {
        fromClassName = styles.blockedDuplicate;
        toClassName = styles.blockedDuplicate;
    }
    const markFromDuplicate = duplicate === 'from' || duplicate === 'each' || duplicate === 'both';
    const markToDuplicate = duplicate === 'to' || duplicate === 'each' || duplicate === 'both';
    const duplicates = (duplicate !== 'none') ? duplicatesProvider(translation, translation.fromLocale) : [];
    return <>
        <div className={cls(styles.inputContainer, styles.from)}>
            <Input value={translation.from} className={cls(fromClassName)}
                onPaste={onPaste}
                onChange={event => onChange({...translation, from: event.target.value}, row)} />
            {markFromDuplicate && <div className={styles.inlineDuplicate}>
                {duplicates.filter(([from, to]) => from === translation.from).map(duplicateRenderer)}
            </div>}
        </div>
        <div className={styles.inputContainer}>
            <Input value={translation.to} className={cls(toClassName)}
                onPaste={onPaste}
                onChange={event => onChange({...translation, to: event.target.value}, row)} />
            {markToDuplicate && <div className={styles.inlineDuplicate}>
                {duplicates.filter(([from, to]) => to === translation.to).map(duplicateRenderer)}
            </div>}
        </div>
    </>;
};
