import {FC, useCallback, useEffect, useReducer} from 'react';
import {LoadIndicator} from '../component/LoadIndicator';
import {useMessageBox} from '../useMessageBox';
import {AddTranslationsView} from '../views/addTranslations/AddTranslationsView';
import {appReducer, DEFAULT_APP_STATE} from './AppState';
import {HomeView} from '../views/home/HomeView';
import {ListTranslationsView} from '../views/listTranslations/ListTranslationsView';
import {LocaleChooserView} from '../views/localeChooser/LocaleChooserView';
import {TrainingView} from '../views/training/TrainingView';
import {Wordings} from '../Wordings';
import {ExportDialog} from '../component/ExportDialog';
import {EditTranslationView} from '../views/editTranslation/EditTranslationView';
import {LanguageChooserView} from '../views/languageChooserView/LanguageChooserView';

let lastSavedData: string | null = null;

export const App: FC = () => {
    const [state, dispatch] = useReducer(appReducer, DEFAULT_APP_STATE);
    const {initialized, showExport} = state;
    const showMessage = useMessageBox();

    const loadTranslationsAndBoxes = useCallback(() => {
        dispatch({type: 'LOAD_START'});
        fetch('/api/load.php', {credentials: 'same-origin'})
            .then(result => result.json())
            .then(({translations, boxes}) => {
                dispatch({type: 'INIT_APP', translations, boxes});
            })
            .catch(reason => {
                console.log('reason', reason);
                showMessage({title: Wordings.error, message: Wordings.loadFailed});
            })
            .finally(() => dispatch({type: 'LOAD_END'}));
    }, [showMessage]);

    const saveTranslationsAndBoxes = useCallback(() => {
        const params = new URLSearchParams();
        const data = JSON.stringify({
            translations: state.translations,
            boxes: state.trainingViewState.boxes,
        });
        if (lastSavedData === data) {
            return;
        }
        lastSavedData = data;
        params.append('data', data);
        dispatch({type: 'LOAD_START'});
        fetch('/api/save.php', {method: 'POST', body: params})
            .then(response => {
                const {status} = response;
                if (status < 200 || status > 399) {
                    throw Error(`HttpStatusError ${status}`);
                }
            })
            .catch(reason => showMessage({title: Wordings.error, message: Wordings.saveFailed}))
            .finally(() => dispatch({type: 'LOAD_END'}));
    }, [showMessage, state]);

    const renderView = () => {
        switch (state.viewName) {
            case 'HOME': return <HomeView dispatch={dispatch} state={state.homeViewState} />;
            case 'ADD_TRANSLATIONS': return <AddTranslationsView dispatch={dispatch} state={state.addTranslationsViewState} allTranslations={state.translations} />;
            case 'LIST_TRANSLATIONS': return <ListTranslationsView dispatch={dispatch} state={state.listTranslationsViewState} />;
            case 'LOCALE_CHOOSER': return <LocaleChooserView dispatch={dispatch} state={state} />;
            case 'TRAINING': return <TrainingView dispatch={dispatch} state={state.trainingViewState} />;
            case 'EDIT_TRANSLATION': return <EditTranslationView dispatch={dispatch} state={state.editTranslationViewState} />;
            case 'LANGUAGE_CHOOSER': return <LanguageChooserView dispatch={dispatch} state={state} />;
        }
    };

    useEffect(() => {
        loadTranslationsAndBoxes();
    }, [loadTranslationsAndBoxes]);

    useEffect(() => {
        if (initialized) {
            saveTranslationsAndBoxes();
        }
    }, [initialized, saveTranslationsAndBoxes]);
    return <main>
        {renderView()}
        {showExport && <ExportDialog state={state} onClose={() => dispatch({type: 'CLOSE_EXPORT'})} />}
        {state.loading > 0 && <LoadIndicator />}
    </main>;
};
