import {cls} from '@goser/common';
import {FC} from 'react';
import {AppActionDispatch} from '../app/AppAction';
import {IconButton} from './IconButton';
import styles from './SimplePaging.module.css';

type NavigationType = 'SHOW_FIRST_PAGE' | 'SHOW_PREVIOUS_PAGE' | 'SHOW_NEXT_PAGE' | 'SHOW_LAST_PAGE';

type Props = {
    className?: string
    dispatch: AppActionDispatch
    page: number
    maxPage: number
}

export const SimplePaging: FC<Props> = ({className, dispatch, page, maxPage}) => {

    const hasPreviousPage = page > 0;
    const hasNextPage = page < maxPage;

    const onNavigationClick = (type: NavigationType) => {
        window.scrollTo(0, 0);
        dispatch({type});
    };

    return <div className={cls(styles.main, className)}>
        <IconButton enabled={hasPreviousPage} onClick={() => onNavigationClick('SHOW_FIRST_PAGE')} char='❘<' />
        <IconButton enabled={hasPreviousPage} onClick={() => onNavigationClick('SHOW_PREVIOUS_PAGE')} char='<' />
        <div>{page + 1}/{maxPage + 1}</div>
        <IconButton enabled={hasNextPage} onClick={() => onNavigationClick('SHOW_NEXT_PAGE')} char='>' />
        <IconButton enabled={hasNextPage} onClick={() => onNavigationClick('SHOW_LAST_PAGE')} char='>❘' />
    </div>;
}