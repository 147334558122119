import {cls} from '@goser/common';
import {Children, FC, isValidElement, PropsWithChildren} from 'react';
import {AppActionDispatch} from '../app/AppAction';
import {Wordings} from '../Wordings';
import {Button} from './Button';
import {SettingsMenu} from './SettingsMenu';
import styles from './View.module.css';

type Props = PropsWithChildren & {
    className?: string;
    title: string;
    dispatch: AppActionDispatch;
};

export const ViewNavbar: FC<PropsWithChildren> = ({children}) => {
    return <>{children}</>;
};
(ViewNavbar as any)["__"] = true;

export const View: FC<Props> = ({className, title, children, dispatch}) => {
    let navbarContent = null;
    children = Children.map(children, child => {
        if (isValidElement(child) && child.type && (child.type as any).__) {
            navbarContent = child;
            return null;
        }
        return child;
    });
    return <div className={cls(className, styles.main)}>
        <div className={styles.header}>
            <div className={styles.home} title={Wordings.toHome} onClick={() => dispatch({type: 'SHOW_HOME'})}></div>
            <div className={styles.title}>{title}</div>
            <SettingsMenu className={styles.settings}>
                <Button onClick={() => dispatch({type: 'EXPORT'})}>Export</Button>
                <Button onClick={() => dispatch({type: 'DEBUG'})}>Debug</Button>
            </SettingsMenu>
        </div>
        <div className={styles.navbar}>{navbarContent}</div>
        <div className={styles.content}>{children}</div>
    </div>;
};