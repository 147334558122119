import {FC, PropsWithChildren} from 'react';
import {Modal} from './Modal';
import styles from './Dialog.module.css';
import {cls} from '@goser/common';

type Props = PropsWithChildren & {
    containerClassName?: string
    dialogClassName?: string
}

export const Dialog: FC<Props> = ({containerClassName, dialogClassName, children}) => {
    return <Modal open={true}>
        <div className={styles.background} />
        <div className={cls(styles.container, containerClassName)}>
            <div className={cls(styles.dialog, dialogClassName)}>
                {children}
            </div>
        </div>
    </Modal>;
}