import {Locale} from '../../model/Locale';
import {Translation, translationLocaleAlignmentMapper} from '../../model/Translation';

type DuplicateType = 'none' | 'from' | 'to' | 'each' | 'both';

export type EditTranslationEntry = Translation & {
    duplicate: DuplicateType
};

const reduceDuplicate = (prev: DuplicateType, from: boolean, to: boolean): DuplicateType => {
    if (prev === 'both' || (from && to)) {
        return 'both';
    }
    if (prev === 'each' || (prev === 'from' && to) || (prev === 'to' && from)) {
        return 'each';
    }
    if (from) {
        return 'from';
    }
    if (to) {
        return 'to';
    }
    return 'none';
}


export const duplicateReducer = <T extends EditTranslationEntry,>(translation: T, allTranslations: Translation[], from: Locale, exclude?: Translation | null) => {
    allTranslations = allTranslations.map(translationLocaleAlignmentMapper(from));
    const found = allTranslations.filter(entry =>
        (entry.fromLocale === translation.fromLocale && entry.from === translation.from) ||
        (entry.toLocale === translation.toLocale && entry.to === translation.to)
    );
    if (found) {
        let duplicate = 'none' as DuplicateType;
        found.forEach(entry => {
            if (exclude) {
                if (exclude.from === entry.from && exclude.to === entry.to) {
                    return duplicate;
                }
            }
            duplicate = reduceDuplicate(
                duplicate,
                translation.from === entry.from,
                translation.to === entry.to)
        });
        return {...translation, duplicate};
    }
    return translation;
}

export const getAllDuplicatesFor = (translation: Translation, allTranslations: Translation[], from: Locale): [string, string][] => {
    return allTranslations
        .map(translationLocaleAlignmentMapper(from))
        .filter(entry =>
            (entry.fromLocale === translation.fromLocale && entry.from === translation.from) ||
            (entry.toLocale === translation.toLocale && entry.to === translation.to)
        ).map(entry => [entry.from, entry.to]);
}

export const toggleDuplicate = (duplicate: DuplicateType): DuplicateType => {
    if (duplicate === 'from') {
        return 'to';
    }
    if (duplicate === 'to') {
        return 'from';
    }
    return duplicate;
};