import {FC, ReactNode} from 'react';
import {useMessageBox} from '../useMessageBox';
import {Wordings} from '../Wordings';
import {Button} from './Button';
import {Dialog} from './Dialog';
import styles from './MessageBox.module.css';

export type MessageBoxOptions = {
    title: ReactNode
    message: ReactNode
    yesCallback?: () => void
    yesLabel?: string
    noCallback?: () => void
    noLabel?: string
    okCallback?: () => void
    okLabel?: string
}

type Props = {
    className?: string
} & MessageBoxOptions;

export const MessageBox: FC<Props> = ({className, title, message, yesCallback, yesLabel, noCallback, noLabel, okCallback, okLabel}) => {
    const showMessage = useMessageBox();
    if (!yesCallback && !noCallback && !okCallback) {
        okCallback = () => showMessage(null);
    }
    return <Dialog dialogClassName={className}>
        <div className={styles.content}>
            <div className={styles.title}>{title}</div>
            <div>{message}</div>
            <div className={styles.buttons}>
                {okCallback && <Button onClick={okCallback}>{okLabel || Wordings.ok}</Button>}
                {yesCallback && <Button onClick={yesCallback}>{yesLabel || Wordings.yes}</Button>}
                {noCallback && <Button onClick={noCallback}>{noLabel || Wordings.no}</Button>}
            </div>
        </div>
    </Dialog>;
}