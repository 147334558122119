import {FC} from 'react';
import {View} from '../../component/View';
import {AppActionDispatch} from '../../app/AppAction';
import {Wordings} from '../../Wordings';
import {Button} from '../../component/Button';
import {SUPPORTED_DIRECTIONS} from '../../model/LocaleDirection';
import {AppState} from '../../app/AppState';

type Props = {
    dispatch: AppActionDispatch;
    state: AppState;
};

export const LanguageChooserView: FC<Props> = ({dispatch, state}) => {

    return <View dispatch={dispatch} title={Wordings.chooseLanguage.title}>
        {SUPPORTED_DIRECTIONS.map(({fromLocale, toLocale}) =>
            <Button
                key={fromLocale + '>' + toLocale}
                onClick={() => dispatch({type: 'CHOOSE_LANGUAGE', target: {fromLocale, toLocale}},)}
            >{Wordings.language[fromLocale]} - {Wordings.language[toLocale]}</Button>
        )}
    </View>;
};