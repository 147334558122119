import {noop} from '@goser/common';
import {PropsWithChildren, FC, createContext, useState, useContext, useCallback, useMemo} from 'react';
import {MessageBox, MessageBoxOptions} from './component/MessageBox';

type ContextType = {
    showMessage: (options: MessageBoxOptions | null) => void
};

const DEFAULT: ContextType = {
    showMessage: noop
};

const Context = createContext<ContextType>(DEFAULT);

type Props = PropsWithChildren;

export const MessageBoxProvider: FC<Props> = ({children}) => {
    const [options, setOptions] = useState<MessageBoxOptions | null>(null);
    const showMessage = useCallback((options: MessageBoxOptions | null) => setOptions(options), []);
    const context = useMemo(() => ({showMessage}), [showMessage]);
    return <Context.Provider value={context}>
        {children}
        {options && <MessageBox {...options} />}
    </Context.Provider>
}

export const useMessageBox = () => useContext(Context).showMessage;