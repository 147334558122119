import {shuffleArray} from '@goser/common';
import {Card, MAX_CARD_LEVEL} from '../../model/Card';
import {Locale} from '../../model/Locale';
import {localeDirectionAnyMatcher, localeDirectionMatcher, matchLocaleDirection} from '../../model/LocaleDirection';
import {Translation, translationLocaleAlignmentMapper} from '../../model/Translation';
import {AppAction} from '../../app/AppAction';

export type Box = {
    cards: Card[];
    level: number;
    fromLocale: Locale;
    toLocale: Locale;
    iteration: number;
    solved: boolean;
};

export type TrainingViewState = {
    boxes: Box[];
    reveal: boolean;
    currentBox: Box | undefined;
    currentCard: Card | undefined;
    fromLocale: Locale;
    toLocale: Locale;
    processed: {
        solved: number;
        unsolved: number;
    };
};

export const DEFAULT_TRAINING_VIEW_STATE: TrainingViewState = {
    boxes: [],
    reveal: false,
    currentBox: undefined,
    currentCard: undefined,
    fromLocale: 'de',
    toLocale: 'en',
    processed: {
        solved: 0,
        unsolved: 0
    }
};

type TrainingViewReducer = (prevState: TrainingViewState, action: AppAction, translations: Translation[]) => TrainingViewState;

const insertCard = (cards: Card[], cardToInsert: Card) => {
    const lastNextLevelCardIndex = cards.findIndex(card => card.level > cardToInsert.level);
    if (lastNextLevelCardIndex === -1) {
        cards.push(cardToInsert);
    } else {
        cards.splice(lastNextLevelCardIndex, 0, cardToInsert);
    }
};

const initializeBox = (state: Box, fromLocale: Locale, toLocale: Locale, translations: Translation[]) => {
    let {iteration, cards, level} = state;
    iteration++;
    translations = translations.filter(localeDirectionAnyMatcher(fromLocale, toLocale))
        .map(translationLocaleAlignmentMapper(fromLocale));
    let hasChanged = false;
    let tempCards: Card[] = [];
    cards.forEach(card => {
        const tempTranslations = card.translations.filter(translation => translations.find(t => t.from === card.text && t.to === translation));
        if (tempTranslations.length !== card.translations.length) {
            hasChanged = true;
        }
        if (tempTranslations.length === 0) {
            // throw away card
            return;
        }
        tempCards.push({...card, translations: tempTranslations});
    });
    translations.forEach(translation => {
        let cardIndex = tempCards.findIndex(card => card.text === translation.from);
        if (cardIndex === -1) {
            insertCard(tempCards, {
                text: translation.from,
                translations: [translation.to],
                level: 0,
                solvedAt: 0,
            });
            hasChanged = true;
        } else {
            const card = tempCards[cardIndex];
            if (!card.translations.includes(translation.to)) {
                tempCards = tempCards.filter((card, index) => index !== cardIndex);
                insertCard(tempCards, {
                    ...card,
                    translations: [...card.translations, translation.to],
                    level: 0,
                });
                hasChanged = true;
            }
        }
    });
    if (hasChanged) {
        cards = tempCards;
    }
    const lowestCard = cards.find(card => card.solvedAt < iteration);
    level = lowestCard ? lowestCard.level : 0;
    return {...state, iteration, cards, level};
};

const moveCard = (cards: Card[], cardToMove: Card, level: number, solvedAt: number) => {
    cards = cards.filter(card => card !== cardToMove);
    cardToMove = {...cardToMove, level, solvedAt};
    insertCard(cards, cardToMove);
    return cards;
};

const getNextLevel = (cards: Card[], level: number, iteration: number) => {
    let nextAvailableCard = cards.find(card => card.level >= level && card.solvedAt < iteration);
    if (!nextAvailableCard || nextAvailableCard.level === MAX_CARD_LEVEL) {
        nextAvailableCard = cards.find(card => card.solvedAt < iteration);
    }
    return nextAvailableCard ? nextAvailableCard.level : 0;
};

const isSolved = (cards: Card[]) => !cards.find(card => card.level < MAX_CARD_LEVEL);

const solveCard = (state: Box, card: Card) => {
    let {cards, level, iteration} = state;
    cards = moveCard(cards, card, Math.min(level + 1, MAX_CARD_LEVEL), iteration);
    level = getNextLevel(cards, level, iteration);
    return {...state, cards, level, solved: isSolved(cards)};
};

const failCard = (state: Box, card: Card) => {
    let {cards, level, iteration} = state;
    cards = moveCard(cards, card, 0, 0);
    level = getNextLevel(cards, level, iteration);
    return {...state, cards, level, solved: isSolved(cards)};
};

const boxReducer = (state: Box, fromLocale: Locale, toLocale: Locale, action: AppAction, translations: Translation[]): Box => {
    switch (action.type) {
        case 'SHOW_TRAINING':
            return initializeBox(state, fromLocale, toLocale, translations);
        case 'CARD_FAILED':
            return failCard(state, action.card);
        case 'CARD_SOLVED':
            return solveCard(state, action.card);
        case 'DOWN_SOLVED_CARDS_ONE_LEVEL':
            return {
                ...state,
                iteration: state.iteration++,
                cards: shuffleArray(state.cards.map(card => ({...card, level: card.level - 1}))),
                level: MAX_CARD_LEVEL - 1
            };
        case 'DOWN_SOLVED_CARDS_TO_LEVEL_ONE':
            return {
                ...state,
                iteration: state.iteration++,
                cards: shuffleArray(state.cards.map(card => ({...card, level: 0}))),
                level: 0
            };
        case 'SHUFFLE_CARDS':
            return {
                ...state,
                cards: shuffleArray(state.cards).sort((a, b) => a.level - b.level),
            };
    }
    return state;
};

const boxesReducer = (state: Box[], fromLocale: Locale, toLocale: Locale, action: AppAction, translations: Translation[]) => {
    const oldBox = state.find(localeDirectionMatcher(fromLocale, toLocale));
    let box = oldBox || {cards: [], level: 0, fromLocale, toLocale, iteration: 0, solved: false};
    box = boxReducer(box, fromLocale, toLocale, action, translations);
    if (box !== oldBox) {
        if (oldBox) {
            return state.map(entry => {
                if (matchLocaleDirection(entry.fromLocale, entry.toLocale, box.fromLocale, box.toLocale)) {
                    return box;
                }
                return entry;
            });
        } else {
            return [...state, box];
        }
    }
    return state;
};

const resolveCurrentValues = (state: TrainingViewState) => {
    const currentBox = state.boxes.find(localeDirectionMatcher(state.fromLocale, state.toLocale));
    if (currentBox !== state.currentBox) {
        state = {...state, currentBox};
    }
    let currentCard: Card | undefined = undefined;
    if (currentBox) {
        const {cards, level} = currentBox;
        currentCard = cards.find(card => card.level === level && card.solvedAt < currentBox.iteration && card.level < MAX_CARD_LEVEL);
    }
    if (currentCard !== state.currentCard) {
        state = {...state, currentCard};
    }
    return state;
};

export const trainingViewReducer: TrainingViewReducer = (state, action, translations) => {
    const {fromLocale, toLocale} = state;
    switch (action.type) {
        case 'CHOOSE_DIRECTION':
            return {
                ...state,
                fromLocale: action.from,
                toLocale: action.to,
            };
        case 'SHOW_TRAINING':
            return resolveCurrentValues({
                ...state,
                reveal: false,
                boxes: boxesReducer(state.boxes, fromLocale, toLocale, action, translations),
            });
        case 'REVEAL_TRANSLATIONS':
            return {
                ...state,
                reveal: true,
            };
        case 'CARD_FAILED':
            return resolveCurrentValues({
                ...state,
                reveal: false,
                processed: {...state.processed, unsolved: state.processed.unsolved + 1},
                boxes: boxesReducer(state.boxes, fromLocale, toLocale, action, translations),
            });
        case 'CARD_SOLVED':
            return resolveCurrentValues({
                ...state,
                reveal: false,
                processed: {...state.processed, solved: state.processed.solved + 1},
                boxes: boxesReducer(state.boxes, fromLocale, toLocale, action, translations),
            });
        case 'DOWN_SOLVED_CARDS_ONE_LEVEL':
        case 'DOWN_SOLVED_CARDS_TO_LEVEL_ONE':
            return resolveCurrentValues({
                ...state,
                boxes: boxesReducer(state.boxes, fromLocale, toLocale, action, translations),
            });
        case 'SHUFFLE_CARDS':
            return resolveCurrentValues({
                ...state,
                boxes: boxesReducer(state.boxes, fromLocale, toLocale, action, translations),
            });
    }
    return state;
};