import {AppAction} from '../../app/AppAction';
import {Translation} from '../../model/Translation';
import {duplicateReducer, EditTranslationEntry} from './EditTranslationEntry';

export type EditTranslationState = EditTranslationEntry & {
    translation: Translation | null;
};

export const DEFAULT_EDIT_TRANSLATION_STATE: EditTranslationState = {
    from: '',
    to: '',
    fromLocale: 'de',
    toLocale: 'en',
    duplicate: 'none',
    translation: null,
};

type EditTranslationReducer = (prev: EditTranslationState, action: AppAction, translations: Translation[]) => EditTranslationState;

export const editTranslationViewReducer: EditTranslationReducer = (state, action, translations) => {
    switch (action.type) {
        case 'EDIT_TRANSLATION':
            return duplicateReducer(
                {
                    ...state,
                    ...action.translation,
                    translation: action.translation,
                },
                translations,
                action.translation.fromLocale,
                action.translation
            );
        case 'UPDATE_EDIT_TRANSLATION_TEXT':
            return duplicateReducer(
                {...state, from: action.from, to: action.to},
                translations,
                state.translation!.fromLocale,
                state.translation
            );
        case 'UPDATE_TRANSLATION':
            return {
                ...state,
                translation: null,
            };
    }
    return state;
};