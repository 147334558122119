import {arePropertiesEqual} from '@goser/common';
import {Reducer} from 'react';
import {Translation} from '../model/Translation';
import {ViewName} from '../model/ViewName';
import {addTranslationsReducer, AddTranslationsState, DEFAULT_ADD_TRANSLATIONS_STATE} from '../views/addTranslations/AddTranslationsState';
import {DEFAULT_EDIT_TRANSLATION_STATE, EditTranslationState, editTranslationViewReducer} from '../views/editTranslation/EditTranslationState';
import {DEFAULT_HOME_VIEW_STATE, homeViewReducer, HomeViewState} from '../views/home/HomeViewState';
import {DEFAULT_LIST_TRANSLATIONS_VIEW_STATE, listTranslationsViewReducer, ListTranslationsViewState} from '../views/listTranslations/ListTranslationsViewState';
import {DEFAULT_TRAINING_VIEW_STATE, trainingViewReducer, TrainingViewState} from '../views/training/TrainingViewState';
import {AppAction} from './AppAction';
import {LocaleDirection} from '../model/LocaleDirection';

export type AppState = {
    viewName: ViewName;
    homeViewState: HomeViewState;
    addTranslationsViewState: AddTranslationsState;
    listTranslationsViewState: ListTranslationsViewState;
    trainingViewState: TrainingViewState;
    editTranslationViewState: EditTranslationState;
    initialized: boolean;
    translations: Translation[];
    loading: number;
    showExport: boolean;
    currentLanguages?: LocaleDirection;
};

export const DEFAULT_APP_STATE: AppState = {
    viewName: 'HOME',
    homeViewState: DEFAULT_HOME_VIEW_STATE,
    addTranslationsViewState: DEFAULT_ADD_TRANSLATIONS_STATE,
    listTranslationsViewState: DEFAULT_LIST_TRANSLATIONS_VIEW_STATE,
    trainingViewState: DEFAULT_TRAINING_VIEW_STATE,
    editTranslationViewState: DEFAULT_EDIT_TRANSLATION_STATE,
    initialized: false,
    translations: [],
    loading: 0,
    showExport: false,
    currentLanguages: undefined,
};

const addTranslations = (translations: Translation[], newTranslations: Translation[]): Translation[] => [...translations, ...newTranslations];

const removeTranslation = (translations: Translation[], translation: Translation): Translation[] =>
    translations.filter(entry => !arePropertiesEqual(entry, translation));

const internalReducer: Reducer<AppState, AppAction> = (state, action) => {
    switch (action.type) {
        case 'INIT_APP':
            return {
                ...state,
                translations: action.translations,
                trainingViewState: {
                    ...DEFAULT_APP_STATE.trainingViewState,
                    boxes: action.boxes,
                },
                initialized: true,
            };
        case 'SHOW_HOME':
            return {...state, viewName: 'HOME'};
        case 'SHOW_ADD_TRANSLATIONS':
            return {...state, viewName: 'ADD_TRANSLATIONS'};
        case 'SHOW_LIST_TRANSLATIONS':
            return {...state, viewName: 'LIST_TRANSLATIONS'};
        case 'SHOW_LOCALE_CHOOSER':
            return {...state, viewName: 'LOCALE_CHOOSER'};
        case 'SHOW_TRAINING':
            return {...state, viewName: 'TRAINING'};
        case 'ADD_TRANSLATIONS':
            return {
                ...state,
                translations: addTranslations(state.translations, action.translations)
            };
        case 'REMOVE_TRANSLATION':
            return {
                ...state,
                translations: removeTranslation(state.translations, action.translation),
            };
        case 'LOAD_START':
            return {...state, loading: state.loading + 1};
        case 'LOAD_END':
            return {...state, loading: state.loading - 1};
        case 'EXPORT':
            return {...state, showExport: true};
        case 'DEBUG':
            console.log(state);
            break;
        case 'CLOSE_EXPORT':
            return {...state, showExport: false};
        case 'EDIT_TRANSLATION':
            return {...state, viewName: 'EDIT_TRANSLATION'};
        case 'UPDATE_TRANSLATION':
            let translations = removeTranslation(state.translations, action.translation);
            translations = addTranslations(translations, [{
                ...action.translation,
                from: action.from,
                to: action.to,
            }]);
            return {...state, translations, viewName: 'HOME'};
        case 'SHOW_LANGUAGE_CHOOSER':
            return {...state, viewName: 'LANGUAGE_CHOOSER'};
        case 'CHOOSE_LANGUAGE':
            return {...state, currentLanguages: action.target, viewName: 'HOME'};
    }
    return state;
};

const applySubReducers: Reducer<AppState, AppAction> = (state, action) => {
    const {translations, currentLanguages} = state;
    const listTranslationsViewState = listTranslationsViewReducer(state.listTranslationsViewState, action, translations, currentLanguages);
    if (listTranslationsViewState !== state.listTranslationsViewState) {
        state = {...state, listTranslationsViewState};
    }
    const addTranslationsViewState = addTranslationsReducer(state.addTranslationsViewState, action, translations, currentLanguages);
    if (addTranslationsViewState !== state.addTranslationsViewState) {
        state = {...state, addTranslationsViewState};
    }
    const trainingViewState = trainingViewReducer(state.trainingViewState, action, translations);
    if (trainingViewState !== state.trainingViewState) {
        state = {...state, trainingViewState};
    }
    const homeViewState = homeViewReducer(state.homeViewState, action, translations, currentLanguages);
    if (homeViewState !== state.homeViewState) {
        state = {...state, homeViewState};
    }
    const editTranslationViewState = editTranslationViewReducer(state.editTranslationViewState, action, translations);
    if (editTranslationViewState !== state.editTranslationViewState) {
        state = {...state, editTranslationViewState};
    }
    return state;
};

export const appReducer: Reducer<AppState, AppAction> = (state, action) => {
    state = internalReducer(state, action);
    state = applySubReducers(state, action);
    if (!state.currentLanguages) {
        state = {...state, viewName: 'LANGUAGE_CHOOSER'};
    }
    return state;
};